/* cyrillic */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6D6MmBp0u-zK4.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6I6MmBp0u-zK4.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6J6MmBp0u-zK4.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@media screen and (min-width:321px) and (max-width:375px){
html{font-size:11px
}
}
@media screen and (min-width:376px) and (max-width:414px){
html{font-size:12px
}
}
@media screen and (min-width:415px) and (max-width:639px){
html{font-size:15px
}
}
@media screen and (min-width:640px) and (max-width:719px){
html{font-size:20px
}
}
@media screen and (min-width:720px) and (max-width:749px){
html{font-size:22.5px
}
}
@media screen and (min-width:750px) and (max-width:799px){
html{font-size:23.5px
}
}
@media screen and (min-width:800px){
html{font-size:25px
}
}
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
h1 {
  font-size: 250%;
}
h2 {
  font-size: 200%;
}
p {
  font-size: 100%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.me[data-v-4f4aa3e6] {
  padding-top: 40px;
  font-family: HelveticaNeue-Bold;
  font-size: 20px;
  -webkit-animation: flicker-data-v-4f4aa3e6 1s linear infinite;
          animation: flicker-data-v-4f4aa3e6 1s linear infinite;
}
.me[data-v-4f4aa3e6]:hover {
  cursor: pointer;
}
.title-wrap[data-v-4f4aa3e6] {
  font-family: HelveticaNeue-Bold;
  padding-top: 40px;
  font-size: 30px;
  color: #181818;
  width: 200px;
  margin: auto;
}
.title-wrap p[data-v-4f4aa3e6] {
  margin: 0;
  font-size: 30px;
  line-height: 40px;
}
.logo-wrap[data-v-4f4aa3e6] {
  width: 100%;
  text-align: center;
  margin-top: 120px;
}
img[data-v-4f4aa3e6] {
  width: 200px;
}
@-webkit-keyframes flicker-data-v-4f4aa3e6 {
0% {
    opacity: 0.3;
}
50% {
    opacity: 1;
}
100% {
    opacity: 0.3;
}
}
@keyframes flicker-data-v-4f4aa3e6 {
0% {
    opacity: 0.3;
}
50% {
    opacity: 1;
}
100% {
    opacity: 0.3;
}
}
.prerender-index .gooey[data-v-714f44d2] {
  background-image: linear-gradient(120deg, #f08080 0%, #f00 100%);
  border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
  width: 150px;
  height: 144px;
  -webkit-animation: morph-data-v-714f44d2 3s linear infinite;
          animation: morph-data-v-714f44d2 3s linear infinite;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  outline: 1px solid transparent;
  will-change: border-radius;
  font-size: 26px;
  color: #fff;
  display: inline-block;
  font-family: 'Pacifico', Helvetica, sans-serif;
}
.prerender-index .gooey[data-v-714f44d2]:before,
.prerender-index .gooey[data-v-714f44d2]:after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
  -webkit-box-shadow: 5px 5px 89px rgba(139,0,0,0.21);
          box-shadow: 5px 5px 89px rgba(139,0,0,0.21);
  will-change: border-radius, transform, opacity;
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
  background-image: linear-gradient(120deg, rgba(255,160,122,0.55) 0%, rgba(255,69,0,0.89) 100%);
}
.prerender-index .gooey[data-v-714f44d2]:before {
  -webkit-animation: morph-data-v-714f44d2 3s linear infinite;
          animation: morph-data-v-714f44d2 3s linear infinite;
  opacity: 0.21;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}
.prerender-index .gooey[data-v-714f44d2]:after {
  -webkit-animation: morph-data-v-714f44d2 3s linear infinite;
          animation: morph-data-v-714f44d2 3s linear infinite;
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
  opacity: 0.89;
  content: "About me";
  line-height: 120px;
  text-indent: -21px;
}
@media only screen and (max-width: 800px) {
.prerender-index svg text[data-v-714f44d2] {
    font-size: 42px;
}
}
@media only screen and (max-width: 500px) {
.prerender-index svg text[data-v-714f44d2] {
    font-size: 36px;
}
}
.prerender-index[data-v-714f44d2] {
  text-align: center;
  background-color: #000;
}
.prerender-index svg[data-v-714f44d2] {
  height: 60vh;
  width: 100%;
}
.prerender-index text[data-v-714f44d2] {
  font-family: 'Pacifico', Helvetica, sans-serif;
  font-size: 50px;
  display: block;
  -webkit-animation: dash-data-v-714f44d2 0.5s linear infinite;
          animation: dash-data-v-714f44d2 0.5s linear infinite;
  fill: #ffa500;
  stroke: #ffa500;
  stroke-width: 0.3px;
  stroke-dashoffset: 30;
  stroke-dasharray: 15;
}
.prerender-index .shadow[data-v-714f44d2] {
  -webkit-filter: drop-shadow(-5px -5px 5px #000);
  filter: drop-shadow(5px 3px 3px #fff) drop-shadow(0px 0px 5px #ffa500) drop-shadow(4px 2px 15px #f00);
}
@-webkit-keyframes dash-data-v-714f44d2 {
to {
    stroke-dashoffset: 0;
}
}
@keyframes dash-data-v-714f44d2 {
to {
    stroke-dashoffset: 0;
}
}
@-webkit-keyframes morph-data-v-714f44d2 {
0%, 100% {
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
            transform: translate3d(0, 0, 0) rotateZ(0.01deg);
}
34% {
    border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
    -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
            transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
}
50% {
    opacity: 0.89;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
            transform: translate3d(0, 0, 0) rotateZ(0.01deg);
}
67% {
    border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
    -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
            transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
}
}
@keyframes morph-data-v-714f44d2 {
0%, 100% {
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
            transform: translate3d(0, 0, 0) rotateZ(0.01deg);
}
34% {
    border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
    -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
            transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
}
50% {
    opacity: 0.89;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
            transform: translate3d(0, 0, 0) rotateZ(0.01deg);
}
67% {
    border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
    -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
            transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
}
}
@-webkit-keyframes fadeIn-data-v-714f44d2 {
100% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    opacity: 0;
}
}
@keyframes fadeIn-data-v-714f44d2 {
100% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    opacity: 0;
}
}
.shake-box[data-v-06a62f4c] {
  position: relative;
  margin: auto;
  width: 26em;
  height: 20em;
}
.shake-box .box[data-v-06a62f4c] {
  position: absolute;
  display: inline-block;
  width: 4em;
  height: 4em;
  border: 1px solid #000;
  border-radius: 10px;
  text-align: center;
  line-height: 4em;
}
.shake-box .animat[data-v-06a62f4c] {
  -webkit-animation: shake-crazy-data-v-06a62f4c 0.75s infinite linear;
          animation: shake-crazy-data-v-06a62f4c 0.75s infinite linear;
}
.shake-box .animat[data-v-06a62f4c]:nth-child(2n) {
  -webkit-animation: shake-crazy-data-v-06a62f4c 0.6s infinite linear;
          animation: shake-crazy-data-v-06a62f4c 0.6s infinite linear;
}
.shake-box .animat[data-v-06a62f4c]:nth-child(2n + 1) {
  -webkit-animation: shake-crazy-data-v-06a62f4c 0.7s infinite linear;
          animation: shake-crazy-data-v-06a62f4c 0.7s infinite linear;
}
@-webkit-keyframes shake-crazy-data-v-06a62f4c {
0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
            transform: translate3d(0, 0, 0) rotate(0deg);
}
17% {
    -webkit-transform: translate3d(0.2px, 0.2px, 0) rotate(4deg);
            transform: translate3d(0.2px, 0.2px, 0) rotate(4deg);
}
34% {
    -webkit-transform: translate3d(-0.2px, -0.2px, 0) rotate(-4deg);
            transform: translate3d(-0.2px, -0.2px, 0) rotate(-4deg);
}
51% {
    -webkit-transform: translate3d(0px, -0.4px, 0) rotate(0deg);
            transform: translate3d(0px, -0.4px, 0) rotate(0deg);
}
68% {
    -webkit-transform: translate3d(0.2px, -0.2px, 0) rotate(4deg);
            transform: translate3d(0.2px, -0.2px, 0) rotate(4deg);
}
85% {
    -webkit-transform: translate3d(-0.2px, 0.2px, 0) rotate(-4deg);
            transform: translate3d(-0.2px, 0.2px, 0) rotate(-4deg);
}
100% {
    -webkit-transform: translate3d(0px, 0.4px, 0) rotate(0deg);
            transform: translate3d(0px, 0.4px, 0) rotate(0deg);
}
}
@keyframes shake-crazy-data-v-06a62f4c {
0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
            transform: translate3d(0, 0, 0) rotate(0deg);
}
17% {
    -webkit-transform: translate3d(0.2px, 0.2px, 0) rotate(4deg);
            transform: translate3d(0.2px, 0.2px, 0) rotate(4deg);
}
34% {
    -webkit-transform: translate3d(-0.2px, -0.2px, 0) rotate(-4deg);
            transform: translate3d(-0.2px, -0.2px, 0) rotate(-4deg);
}
51% {
    -webkit-transform: translate3d(0px, -0.4px, 0) rotate(0deg);
            transform: translate3d(0px, -0.4px, 0) rotate(0deg);
}
68% {
    -webkit-transform: translate3d(0.2px, -0.2px, 0) rotate(4deg);
            transform: translate3d(0.2px, -0.2px, 0) rotate(4deg);
}
85% {
    -webkit-transform: translate3d(-0.2px, 0.2px, 0) rotate(-4deg);
            transform: translate3d(-0.2px, 0.2px, 0) rotate(-4deg);
}
100% {
    -webkit-transform: translate3d(0px, 0.4px, 0) rotate(0deg);
            transform: translate3d(0px, 0.4px, 0) rotate(0deg);
}
}
p[data-v-5cad5f90] {
  padding-left: 20px;
  font-size: 20px;
  line-height: 40px;
}

.upload section[data-v-598ada2c] {
  margin: 20px;
  width: 500px;
  border: 1px solid #bfcbd9;
  border-radius: 5px;
  padding: 20px 10px;
  overflow: hidden;
}
.upload section .img-box[data-v-598ada2c] {
  width: 100px;
  height: 100px;
  display: inline-block;
}
.upload section .multiple-img-wrap[data-v-598ada2c] {
  width: 500px;
}
.upload section h2[data-v-598ada2c] {
  margin: 0;
}
.upload section img[data-v-598ada2c] {
  display: inline-block;
  padding: 5px;
  width: 100px;
  height: 100px;
}
.upload section button[data-v-598ada2c] {
  padding: 7px 9px;
  font-size: 12px;
  border-radius: 4px;
  color: #fff;
  background-color: #20a0ff;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  margin-right: 20px;
}
.upload section input[data-v-598ada2c] {
  display: none;
}